import {
  BaselineWithExperiencesEntry,
  Entry,
  ExperienceMapper
} from '@ninetailed/experience.js-utils-contentful';

export const parsePage = page => {
  // Kill circular references
  return JSON.parse(JSON.stringify(page));
};

export const parseExperiences = entry => {
  return hasExperiences(entry)
    ? entry.fields.nt_experiences
        .filter(experience => ExperienceMapper.isExperienceEntry(experience))
        .map(experience => ExperienceMapper.mapExperience(experience))
    : [];
};

export const hasExperiences = entry => {
  return entry?.fields?.nt_experiences !== undefined;
};
